import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <div className="blog-post page">
        <div className="blog-post-container">
          <div className="blog-post">
            <h1>{frontmatter.title}</h1>
            <span className="break-line"></span>
            <div
              className="blog-post-content mt-3"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
        {/* <div className="row mt-5 mb-3">
          <div className="col-sm-12 col-md-4">
            <button className="btn btn-sm btn-outline-primary">
              Previous Blogs
            </button>
          </div>
          <div className="col-sm-12 col-md-4 text-center">
            <button className="btn btn-sm btn-primary">All Blogs</button>
          </div>
          <div className="col-sm-12 col-md-4 text-right">
            <button className="btn btn-sm btn-outline-primary">
              Next Blogs
            </button>
          </div>
        </div> */}
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
